
import Vue from "vue";
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer.vue";
import ForgotPassword from "@/components/ForgotPassword/ForgotPassword.vue";

export default Vue.extend({
  name: "ForgotPasswordPage",
  components: {
    Header,
    Footer,
    ForgotPassword
  },
  props: {
    userType: {
      type: String,
      default: "mentee"
    }
  }
});
